.#{$prefix}tabs {
  display: flex;
  flex-direction: column;
}

.#{$prefix}tab-bar {
  --#{$prefix}tab-bar-item-padding-x: #{$spacer-sm};
  --#{$prefix}tab-bar-item-padding-y: #{$spacer-sm};
  flex: 1;
  display: flex;
  border-bottom: 1px solid var(--#{$prefix}border-color);

  &.horizontal {
    flex-direction: row;
    overflow-x: auto;
  }

  .TabItem {
    --#{$prefix}tab-bar-color: var(--#{$prefix}secondary-color);
    --#{$prefix}tab-bar-indicator-selected-color: transparent;

    height: 100%;
    padding: 0 $spacer-sm;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    transition: background-color 200ms;
    cursor: pointer;
    user-select: none;
    font-weight: 600;
    color: var(--#{$prefix}tab-bar-color);

    &:hover {
      background-color: var(--#{$prefix}secondary-bg);
    }

    &:active {
      background-color: var(--#{$prefix}secondary-bg);
    }

    &.selected {
      --#{$prefix}tab-bar-color: #{$primary};
      --#{$prefix}tab-bar-indicator-selected-color: #{$primary};
    }

    .content {
      flex: 1;
      display: flex;
      align-items: center;
      padding: var(--#{$prefix}tab-bar-item-padding-y) var(--#{$prefix}tab-bar-item-padding-x);
    }

    .indicator {
      width: 100%;
      height: 3px;
      border-radius: 10px;
      background-color: var(--#{$prefix}tab-bar-indicator-selected-color);
    }


  }
}