$prefix: 'mexui-';
$app-left-nav-bar-width: 200px;
$app-left-nav-bar-width-lg: 260px;
$app-top-nav-bar-height: 52px;
$app-profile-modal-width: 280px;

$app-base-modal-zIndex: 1000;
$app-profile-modal-zIndex: 2000;

$app-chat-session-list-width: 300px;
$app-chat-session-list-width-lg: 360px;
$app-chat-session-list-width-sm: 300px;

$app-nav-modal-header-height: 60px;
$app-nav-modal-height: 90vh;
$app-nav-modal-min-height: 500px;
$app-nav-modal-max-height: 800px;

$app-chat-header-height: 64px;