@import "../../stories/stylesheets/functions";
@import "../mixins/breakpoints";

.WorkspaceWindowPage {
  $tabBarHeight: 40px;
  --app-workspace-body-height: #{subtract(subtract(100vh,$app-chat-header-height),$tabBarHeight)};
  --app-chat-body-bg: var(--#{$prefix}body-bg);
  position: relative;

  .Header, .TabBar {
    height: $app-chat-header-height;
    z-index: 1;
    padding: 0 15px;
    display: flex;
    align-items: center;
  }

  .TabBar {
    height: $tabBarHeight;
    user-select: none;
    @apply border-b;

    .TabItem {
      height: 100%;
      min-width: 70px;
      padding: 0 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      transition: background-color 200ms;

      &:hover {
        background-color: var(--#{$prefix}body-hover-bg);
      }

      &.actived {
        .label {
          color: var(--#{$prefix}primary);
        }

        .indicator {
          background-color: var(--#{$prefix}primary);
        }
      }

      .label {
        flex: 1;
        padding: 0 10px;
        display: flex;
        align-items: center;
        font-weight: 600;
      }

      .indicator {
        width: 100%;
        height: 3px;
        border-radius: 10px;
        background-color: transparent;
      }
    }
  }

  .Body {
    height: var(--app-workspace-body-height);
    position: relative;
    @apply bg-gray-100 dark:bg-stone-900;
  }


  @include media-breakpoint-showSmallApp() {
    --app-workspace-body-height: #{subtract(subtract(subtract(100vh,$app-chat-header-height),$tabBarHeight),$app-top-nav-bar-height)};
  }

}



.ChatMessagePane {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .Body {
    flex: 1;
    padding-left: 20px;
    padding-right: 20px;
    overflow-y: auto;

    .MessageList {
      width: 100%;
      height: 100%;

      &:before, &:after {
        content: ' ';
        display: block;
        height: 20px;
      }

      &:before {
        height: 10px;
      }
    }
  }


  .Footer {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;

    @include media-breakpoint-showLargeApp(){
      padding: 20px;
      padding-top: 0;
    }
  }

}

.WorkspaceWindowFile {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  .Body {
    flex: 1;
    overflow: hidden;
  }


  .Footer {
    height: 22vh;
    min-height: 150px;
    max-height: 300px;
    padding: 20px;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }

}

.WorkspaceClientPane {
  flex: 1;
  height: 100%;
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  .Body {
    flex: 1;
    padding: 20px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .clientInfo {
    padding: 0 20px;
    width: 52vw;
    min-width: 200px;
    box-sizing: border-box;
    max-width: 800px;

    .Section {
      padding: 20px 0;
      border-radius: 10px;
      margin-bottom: 20px;
      background-color: var(--mexui-body-bg);
    }

    .sectionTitle {
      padding: 10px 0;
      color: #999;
      font-weight: 600;
      max-width: 200px;
      box-sizing: border-box;
      padding-left: 20px;
    }

    .item {
      display: flex;
      align-items: center;
      padding: 10px 0;
      line-height: 1.3;

      .label {
        flex-shrink: 0;
        width: 15vw;
        max-width: 220px;
        text-align: right;
        padding: 0 20px;
        box-sizing: border-box;
        color: var(--mexui-tertiary-color);
        font-weight: 600;
      }

      .value {
        word-break: break-word;
        padding: 5px 10px;
        box-sizing: border-box;
        margin-right: 10px;

        &.copyBox {
          cursor: pointer;
          border-radius: 5px;

          &:hover {
            background-color: rgba(0, 0, 0, 0.03);
          }
        }
      }

    }
  }
}

.WorkspaceTaskPane {
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
  }

  .Body {
    height: 100%;
    flex: 1;
    display: flex;
    flex-direction: column;
  }

}