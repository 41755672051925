@import "../stories/stylesheets/variables";

.rc-dropdown {
  position: absolute;
  left: -9999px;
  top: -9999px;
  z-index: 1070;
  display: block;
  line-height: 1.5;

  .rc-menu {
    outline: none;
    position: relative;
    list-style-type: none;
    padding: 5px;
    margin: 2px 0 2px;
    text-align: left;
    background-clip: padding-box;
    border-radius: 6px;
    user-select: none;
    border: 1px solid var(--#{$prefix}dropdown-border-color);
    box-shadow: var(--#{$prefix}dropdown-box-shadow);
    background-color: var(--#{$prefix}dropdown-bg);
    min-width: 150px;

    .#{$prefix}divider {
      --#{$prefix}divider-bg: var(--#{$prefix}dropdown-divider-bg);
      margin-left: 10px;
      margin-right: 10px;
    }

    .rc-menu-item {
      border-radius: 5px;
      padding: 5px 20px 5px 12px;
      cursor: pointer;
      transition: background-color 200ms;
      display: flex;
      align-items: center;
      gap: 10px;

      &:hover {
        background-color: var(--#{$prefix}dropdown-item-hover-bg);
      }

      &:active {
        background-color: var(--#{$prefix}dropdown-item-active-bg);
      }
    }
  }
}

.rc-dropdown-hidden {
  display: none;
}

.rc-dropdown .rc-menu > li {
  margin: 0;
  padding: 0;
}

.rc-dropdown .rc-menu:before {
  content: "";
  position: absolute;
  top: -4px;
  left: 0;
  width: 100%;
  height: 4px;
  background: #ffffff;
  background: rgba(255, 255, 255, 0.01);
}

.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item {
  position: relative;
  display: block;
  padding: 7px 10px;
  clear: both;
  color: #666666;
  white-space: nowrap;
}

.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item:hover,
.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item-active,
.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item-selected {
  background-color: #eee;
}

.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item-selected {
  position: relative;
}

.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item-selected:after {
  content: '\e613';
  font-family: 'anticon';
  font-weight: bold;
  position: absolute;
  top: 6px;
  right: 16px;
  color: #3CB8F0;
}

.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item-disabled {
  color: #ccc;
  cursor: not-allowed;
  pointer-events: none;
}

.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item-disabled:hover {
  color: #ccc;
  background-color: #fff;
  cursor: not-allowed;
}

.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item:last-child {
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
}

.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item:first-child {
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.rc-dropdown .rc-menu > .rc-dropdown .rc-menu-item-divider {
  height: 1px;
  margin: 1px 0;
  overflow: hidden;
  background-color: #e5e5e5;
  line-height: 0;
}

.rc-dropdown-slide-up-enter,
.rc-dropdown-slide-up-appear {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
  opacity: 0;
  animation-timing-function: cubic-bezier(0.08, 0.82, 0.17, 1);
  animation-play-state: paused;
}

.rc-dropdown-slide-up-leave {
  animation-duration: 0.1s;
  animation-fill-mode: both;
  transform-origin: 0 0;
  display: block !important;
  opacity: 1;
  animation-timing-function: cubic-bezier(0.6, 0.04, 0.98, 0.34);
  animation-play-state: paused;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-bottomRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-bottomRight {
  animation-name: rcDropdownSlideUpIn;
  animation-play-state: running;
}

.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-enter.rc-dropdown-slide-up-enter-active.rc-dropdown-placement-topRight,
.rc-dropdown-slide-up-appear.rc-dropdown-slide-up-appear-active.rc-dropdown-placement-topRight {
  animation-name: rcDropdownSlideDownIn;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-bottomRight {
  animation-name: rcDropdownSlideUpOut;
  animation-play-state: running;
}

.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topLeft,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topCenter,
.rc-dropdown-slide-up-leave.rc-dropdown-slide-up-leave-active.rc-dropdown-placement-topRight {
  animation-name: rcDropdownSlideDownOut;
  animation-play-state: running;
}

@keyframes rcDropdownSlideUpIn {
  0% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
}

@keyframes rcDropdownSlideUpOut {
  0% {
    opacity: 1;
    transform-origin: 0% 0%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 0%;
    transform: scaleY(0);
  }
}

@keyframes rcDropdownSlideDownIn {
  0% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
  100% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
}

@keyframes rcDropdownSlideDownOut {
  0% {
    opacity: 1;
    transform-origin: 0% 100%;
    transform: scaleY(1);
  }
  100% {
    opacity: 0;
    transform-origin: 0% 100%;
    transform: scaleY(0);
  }
}

.rc-dropdown-arrow {
  position: absolute;
  border-width: 4px;
  border-color: transparent;
  box-shadow: 0 1px 5px #ccc;
  border-style: solid;
  transform: rotate(45deg);
}

.rc-dropdown-show-arrow.rc-dropdown-placement-top,
.rc-dropdown-show-arrow.rc-dropdown-placement-topLeft,
.rc-dropdown-show-arrow.rc-dropdown-placement-topRight {
  padding-bottom: 6px;
}

.rc-dropdown-show-arrow.rc-dropdown-placement-bottom,
.rc-dropdown-show-arrow.rc-dropdown-placement-bottomLeft,
.rc-dropdown-show-arrow.rc-dropdown-placement-bottomRight {
  padding-top: 6px;
}

.rc-dropdown-placement-top .rc-dropdown-arrow,
.rc-dropdown-placement-topLeft .rc-dropdown-arrow,
.rc-dropdown-placement-topRight .rc-dropdown-arrow {
  bottom: 4px;
  border-top-color: white;
}

.rc-dropdown-placement-top .rc-dropdown-arrow {
  left: 50%;
}

.rc-dropdown-placement-topLeft .rc-dropdown-arrow {
  left: 15%;
}

.rc-dropdown-placement-topRight .rc-dropdown-arrow {
  right: 15%;
}

.rc-dropdown-placement-bottom .rc-dropdown-arrow,
.rc-dropdown-placement-bottomLeft .rc-dropdown-arrow,
.rc-dropdown-placement-bottomRight .rc-dropdown-arrow {
  top: 4px;
  border-bottom-color: white;
}

.rc-dropdown-placement-bottom .rc-dropdown-arrow {
  left: 50%;
}

.rc-dropdown-placement-bottomLeft .rc-dropdown-arrow {
  left: 15%;
}

.rc-dropdown-placement-bottomRight .rc-dropdown-arrow {
  right: 15%;
}
