.#{$prefix}badge {
  background-color: $danger;
  color: white;
  min-width: 14px;
  text-align: center;
  max-width: max-content;
  font-weight: 600;

  &.size-xs {
    padding: 0 1px;
    border-radius: 3px;
    font-size:var(--#{$prefix}font-size-xs);
  }

  &.size-sm {
    padding: 0 2px;
    border-radius: 4px;
    font-size:var(--#{$prefix}font-size-sm);
  }

  &.size-md {
    padding: 0 5px;
    border-radius: 5px;
  }

  &.size-lg {
    padding:0 6px;
    border-radius: 8px;
    font-size:var(--#{$prefix}font-size-lg);
  }

}

.#{$prefix}badgedot {
  background-color: $danger;
  border-radius: 1000px;

  &.size-xs {
    width: 5px;
    height: 5px;
  }

  &.size-sm {
    width: 6px;
    height: 6px;
  }

  &.size-md {
    width: 8px;
    height: 8px;
  }

  &.size-lg {
    width: 10px;
    height: 10px;
  }
}