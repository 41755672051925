//
// Rotating border
//

.#{$prefix}spinner {

  display: inline-block;
  width: var(--#{$prefix}spinner-width);
  height: var(--#{$prefix}spinner-height);
  vertical-align: var(--#{$prefix}spinner-vertical-align);
  // stylelint-disable-next-line property-disallowed-list
  border-radius: 50%;
  animation: var(--#{$prefix}spinner-animation-speed) linear infinite var(--#{$prefix}spinner-animation-name);

  &.variant-border {
    // scss-docs-start spinner-border-css-vars
    --#{$prefix}spinner-width: #{$spinner-width};
    --#{$prefix}spinner-height: #{$spinner-height};
    --#{$prefix}spinner-vertical-align: #{$spinner-vertical-align};
    --#{$prefix}spinner-border-width: #{$spinner-border-width};
    --#{$prefix}spinner-animation-speed: #{$spinner-animation-speed};
    --#{$prefix}spinner-animation-name: spinner-border;
    // scss-docs-end spinner-border-css-vars

    border: var(--#{$prefix}spinner-border-width) solid currentcolor;
    border-right-color: transparent;

    &.size-xs {
      --#{$prefix}spinner-width: #{$spinner-width-xs};
      --#{$prefix}spinner-height: #{$spinner-height-xs};
      --#{$prefix}spinner-border-width: #{$spinner-border-width-xs};
    }

    &.size-sm {
      --#{$prefix}spinner-width: #{$spinner-width-sm};
      --#{$prefix}spinner-height: #{$spinner-height-sm};
      --#{$prefix}spinner-border-width: #{$spinner-border-width-sm};
    }

    &.size-lg {
      --#{$prefix}spinner-width: #{$spinner-width-lg};
      --#{$prefix}spinner-height: #{$spinner-height-lg};
      --#{$prefix}spinner-border-width: #{$spinner-border-width-lg};
    }
  }

  &.variant-grow {
    // scss-docs-start spinner-grow-css-vars
    --#{$prefix}spinner-width: #{$spinner-width};
    --#{$prefix}spinner-height: #{$spinner-height};
    --#{$prefix}spinner-vertical-align: #{$spinner-vertical-align};
    --#{$prefix}spinner-animation-speed: #{$spinner-animation-speed};
    --#{$prefix}spinner-animation-name: spinner-grow;
    // scss-docs-end spinner-grow-css-vars

    background-color: currentcolor;
    opacity: 0;

    &.size-xs {
      --#{$prefix}spinner-width: #{$spinner-width-xs};
      --#{$prefix}spinner-height: #{$spinner-height-xs};
    }

    &.size-sm {
      --#{$prefix}spinner-width: #{$spinner-width-sm};
      --#{$prefix}spinner-height: #{$spinner-height-sm};
    }

    &.size-lg {
      --#{$prefix}spinner-width: #{$spinner-width-lg};
      --#{$prefix}spinner-height: #{$spinner-height-lg};
    }
  }

}

// scss-docs-start spinner-border-keyframes
@keyframes spinner-border {
  to {
    transform: rotate(360deg) #{"/* rtl:ignore */"};
  }
}

// scss-docs-end spinner-border-keyframes


//
// Growing circle
//

// scss-docs-start spinner-grow-keyframes
@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}

// scss-docs-end spinner-grow-keyframes


@if $enable-reduced-motion {
  @media (prefers-reduced-motion: reduce) {
    .#{$prefix}spinner {
      --#{$prefix}spinner-animation-speed: #{$spinner-animation-speed * 2};
    }
  }
}
