@import "../../stories/stylesheets/functions";
@import "../../stylesheets/mixins/breakpoints";

.ChatMessageInputer {
  $footerHeight: 50px;

  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  background-color: var(--#{$prefix}body-bg);
  border-top: 1px solid var(--#{$prefix}border-color);

  @include media-breakpoint-showLargeApp(){
    @apply rounded-lg;
    border: 1px solid var(--#{$prefix}border-color);
  }

  .Header {
    display: flex;
    padding: 7px;
  }

  .IconBtn {
    margin: 0 4px;
  }

  .BtnSeparator {
    padding: 8px;

    &:after {
      content: ' ';
      display: block;
      width: 1px;
      height: 100%;
      background-color: #bebebe;

    }
  }

  .MentionsInput {

    flex: 1;
    overflow: hidden!important;
    max-height: 200px!important;
  }

  .MentionsInput__suggestions{
    background-color: #1c63cd;
  }

  .Footer {
    display: flex;
    justify-content: space-between;
    padding: 5px 20px 10px 5px;
    height: $footerHeight;
    box-sizing: border-box;
    cursor: text;

    .sendBtn {
      width: 100px;
      padding-left: 0;
    }
  }


}