@import "../../stylesheets/mixins/breakpoints";

.AppContainer {
  --app-container-height: 100vh;
  width: 100vw;
  height: var(--app-container-height);
  display: flex;
  @include media-breakpoint-showSmallApp() {
    --app-container-height: #{subtract(100vh,$app-top-nav-bar-height)}
  }

  .main {
    flex: 1;
    overflow: auto;
  }
}
