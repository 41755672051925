$treeRootCls: 'mexui-file-tree';
$treePrefixCls: 'mexui-file-tree';
$treeNodePrefixCls: #{$treePrefixCls}-treenode;

$nodeHeight: 42px;

.fileList {
  overflow-x: auto;
  height: calc(100vh - 140px);
  background-color: var(--mexui-body-bg);
  border-radius: 10px;
}

.mexui-file-tree {
  border-radius: 0;
  background-color: transparent;
  color: var(--mexui-body-color);

  .ant-tree-treenode {
    position: relative;
    height: $nodeHeight;
    padding: 0 !important;
    align-items: center !important;
    border-bottom: 1px solid var(--mexui-border-tertiary-color);
    transition: background-color 100ms;
    width: max-content;

    &:hover {
      background-color: var(--mexui-secondary-bg);
      .ant-tree-checkbox {
        display: block;
      }
    }

    &.ant-tree-treenode-checkbox-checked {
      background-color: var(--mexui-secondary-bg);

      .ant-tree-checkbox {
        display: block;
      }
    }

    //  拖拽 icon
    .ant-tree-draggable-icon {
      display: none;
    }

    //  展开 icon
    .ant-tree-switcher {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;

      .ant-tree-switcher-icon {
        color: #666;
        font-size: 12px;
      }
    }

    //  复选框icon
    .ant-tree-checkbox {
      display: none;
      position: absolute;
      right: 0;
      z-index: 100;
    }

    .ant-tree-node-content-wrapper {
      display: flex;
      align-items: center;
      padding: 0 !important;
      cursor: inherit !important;

      &:hover {
        background-color: transparent !important;
      }

      &.ant-tree-node-selected {
        background-color: transparent !important;
      }

      .ant-tree-iconEle {
        display: flex !important;
        align-items: center;
        width: 32px !important;
        height: $nodeHeight !important;
      }
    }
  }

  .nameCell,
  .modifiedCell,
  .sizeCell {
    padding-left: 12px;
    padding-right: 5px;
    box-sizing: border-box;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .nameCell {
    width: 30vw;
    padding-left: 0;

    a:hover {
      color: inherit;
      text-decoration: underline;
    }
  }
}

