
//:root {
//  --contexify-zIndex: 666;
//  --contexify-menu-minWidth: 220px;
//  --contexify-menu-padding: 6px;
//  --contexify-menu-radius: 6px;
//  --contexify-menu-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1),
//  2px 4px 4px rgba(0, 0, 0, 0.1),
//  3px 6px 6px rgba(0, 0, 0, 0.1);
//  --contexify-menu-negatePadding: 6px;
//  --contexify-separator-color: rgba(0, 0, 0, 0.2);
//  --contexify-separator-margin: 5px;
//  --contexify-itemContent-padding: 6px;
//  --contexify-activeItem-radius: 4px;
//
//  --contexify-menu-bgColor: #ffffff;
//  --contexify-separator-color: #eeeeee;
//  --contexify-item-color: #222222;
//  --contexify-activeItem-color: #222222;
//  --contexify-activeItem-bgColor: #eeeeee;
//  --contexify-rightSlot-color: #6f6f6f;
//  --contexify-activeRightSlot-color: #6f6f6f;
//  --contexify-arrow-color: #6f6f6f;
//  --contexify-activeArrow-color: #6f6f6f;
//}

@keyframes contexify_feedback {
  from {
    opacity: 0.4;
  }
  to {
    opacity: 1;
  }
}

.contexify {
  position: fixed;
  opacity: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  box-sizing: border-box;
  padding: 6px;
  z-index: 666;
  font-weight: 400;
  min-width: 200px;
  border-radius: 4px;
  box-shadow: var(--mexui-box-shadow);
  background-color: var(--mexui-dropdown-bg);
}

.contexify_submenu-isOpen, .contexify_submenu-isOpen > .contexify_itemContent {
  color: var(--contexify-activeItem-color);
  background-color: var(--contexify-activeItem-bgColor);
  border-radius: var(--contexify-activeItem-radius);
}

.contexify_submenu-isOpen > .contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-activeArrow-color);
}

.contexify_submenu-isOpen > .contexify_submenu {
  pointer-events: initial;
  opacity: 1;
}

.contexify .contexify_submenu {
  position: absolute;
  pointer-events: none;
  transition: opacity 0.265s;
  /* Initial submenu position */
  top: calc(-1 * var(--contexify-menu-negatePadding));
  left: 100%;
}

.contexify .contexify_submenu-bottom {
  bottom: calc(-1 * var(--contexify-menu-negatePadding));
  top: unset;
}

.contexify .contexify_submenu-right {
  right: 100%;
  left: unset;
}

.contexify_rightSlot {
  margin-left: auto;
  display: -ms-flexbox;
  display: flex;
  color: var(--contexify-rightSlot-color);
}

.contexify_separator {
  height: 1px;
  cursor: default;
  margin: var(--mexui-spacer-sm);
  background-color:var(--mexui-border-color);
}

.contexify_willLeave-disabled {
  pointer-events: none;
}

.contexify_item {
  cursor: pointer;
  position: relative;
}

.contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent .contexify_rightSlot, .contexify_item:focus .contexify_rightSlot {
  color: var(--contexify-activeRightSlot-color);
}

.contexify_item:not(.contexify_item-disabled)[aria-haspopup] > .contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-arrow-color);
}

.contexify_item[aria-haspopup]:focus > .contexify_itemContent .contexify_rightSlot, .contexify_item:not(.contexify_item-disabled)[aria-haspopup].contexify_submenu-isOpen > .contexify_itemContent .contexify_rightSlot, .contexify_item:not(.contexify_item-disabled)[aria-haspopup]:hover > .contexify_itemContent .contexify_rightSlot {
  color: var(--contexify-activeArrow-color);
}

.contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent{
  background-color: var(--mexui-dropdown-item-hover-bg);
}
.contexify_item:not(.contexify_item-disabled):active > .contexify_itemContent{
  background-color: var(--mexui-dropdown-item-active-bg);
}

.contexify_item:not(.contexify_item-disabled):hover > .contexify_submenu {
  pointer-events: initial;
  opacity: 1;
}

.contexify_item-disabled {
  cursor: default;
  opacity: 0.5;
}

.contexify_itemContent {
  padding: 2px 5px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  border-radius: $border-radius-sm;


  .prefixIcon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    margin-right: 4px;
  }
}


.contexify_item-feedback {
  //animation: contexify_scaleIn 0.12s both;
}

@keyframes contexify_scaleIn {
  from {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
  to {
    opacity: 1;
  }
}

@keyframes contexify_scaleOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d(0.3, 0.3, 0.3);
  }
}

.contexify_willEnter-scale {
  transform-origin: top left;
  animation: contexify_scaleIn 0.3s;
}

.contexify_willLeave-scale {
  transform-origin: top left;
  animation: contexify_scaleOut 0.3s;
}

@keyframes contexify_fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes contexify_fadeOut {
  from {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(10px);
  }
}

.contexify_willEnter-fade {
  animation: contexify_fadeIn 0.3s ease;
}

.contexify_willLeave-fade {
  animation: contexify_fadeOut 0.3s ease;
}

@keyframes contexify_flipInX {
  from {
    transform: perspective(800px) rotate3d(1, 0, 0, 45deg);
  }
  to {
    transform: perspective(800px);
  }
}

@keyframes contexify_flipOutX {
  from {
    transform: perspective(800px);
  }
  to {
    transform: perspective(800px) rotate3d(1, 0, 0, 45deg);
    opacity: 0;
  }
}

.contexify_willEnter-flip {
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
  transform-origin: top center;
  animation: contexify_flipInX 0.3s;
}

.contexify_willLeave-flip {
  transform-origin: top center;
  animation: contexify_flipOutX 0.3s;
  -webkit-backface-visibility: visible !important;
  backface-visibility: visible !important;
}

@keyframes contexify_slideIn {
  from {
    opacity: 0;
    transform: scale3d(1, 0.3, 1);
  }
  to {
    opacity: 1;
  }
}

@keyframes contexify_slideOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d(1, 0.3, 1);
  }
}

.contexify_willEnter-slide {
  transform-origin: top center;
  animation: contexify_slideIn 0.3s;
}

.contexify_willLeave-slide {
  transform-origin: top center;
  animation: contexify_slideOut 0.3s;
}
