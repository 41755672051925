@include deprecate("`alert-variant()`", "v5.3.0", "v6.0.0");

@mixin alert-variant($background, $border, $color) {
  --#{$prefix}alert-color: #{$color};
  --#{$prefix}alert-bg: #{$background};
  --#{$prefix}alert-border-color: #{$border};
  --#{$prefix}alert-link-color: #{shade-color($color, 20%)};

  @if $enable-gradients {
    background-image: var(--#{$prefix}gradient);
  }

  .alert-link {
    color: var(--#{$prefix}alert-link-color);
  }
}

// scss-docs-end alert-variant-mixin

// scss-docs-start btn-size-mixin
@mixin alert-size($padding-y, $padding-x, $font-size, $border-radius) {
  --#{$prefix}alert-padding-y: #{$padding-y};
  --#{$prefix}alert-padding-x: #{$padding-x};
  @include rfs($font-size, --#{$prefix}alert-font-size);
  --#{$prefix}alert-border-radius: #{$border-radius};
}

// scss-docs-end btn-size-mixin