.#{$prefix}table {
  width: 100%;
  border-bottom: 1px solid rgba(55, 53, 47, 0.09);


  tr {
    width: 100%;
  }

  th {
    text-align: inherit;
    font-weight: inherit;
  }

  th, td {
    padding: 8px 0;
  }

  thead {
    tr {
      color: var(--color-text-light);
      user-select: none;
    }
  }

  tbody {
    overflow-y: auto;
    tr {
      border-top: 1px solid rgba(55, 53, 47, 0.09);
    }
  }
}