.AppLoadingScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .animation {
    width: 200px;
    height: 200px;
  }
}