@import "../../../stories/stylesheets/functions";
@import "../../../stories/stylesheets/variables";
@import "../../../stories/stylesheets/variables-dark";
@import "../../../stories/stylesheets/mixins/color-mode";
@import "../../../stories/stylesheets/mixins/text-truncate";
@import "../../../stylesheets/mixins/breakpoints";

.ProfileModal {
  $offset-left: -10px;
  $offset-top: 10px;
  --app-profile-modal-left: #{add($app-left-nav-bar-width-lg,$offset-left)};
  --app-profile-modal-offset-top: #{$offset-top};

  left: var(--app-profile-modal-left);
  top: var(--app-profile-modal-offset-top);
  width: $app-profile-modal-width;
  max-height: 400px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid var(--#{$prefix}border-color);
  box-shadow: var(--#{$prefix}box-shadow-lg);

  @include media-breakpoint-showMediumApp() {
    --app-profile-modal-left: #{add($app-left-nav-bar-width,$offset-left)};
  }
  @include media-breakpoint-showSmallApp() {
    --app-profile-modal-offset-top: #{add($app-top-nav-bar-height,-5px)};
    --app-profile-modal-left: #{add(subtract(100vw,$app-profile-modal-width),$offset-left)};
  }

  .item {
    display: flex;
    align-items: center;
    padding: 10px 15px;
    border-radius: 5px;
    cursor: pointer;

    &:hover {
      background-color: var(--#{$prefix}dropdown-item-hover-bg);
    }

    &:active {
      background-color: var(--#{$prefix}dropdown-item-active-bg);
    }
  }

}

.ProfileModalOverlay {
  z-index: $app-profile-modal-zIndex;
}


.TeamListContent {
  height: 100%;
  display: flex;
  flex-direction: column;

  .TeamList {
    flex: 1;
    padding-top: 10px;
  }

  .TeamItem {
    width: 100%;
    justify-content: flex-start;
    padding: 6px 8px;
    cursor: pointer;
    border-radius: 5px;

    .icon {
      color: #1c63cd;
    }

    .name {
      flex: 1;
      padding: 0 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: var(--#{$prefix}body-font-size-sm);
    }
  }
}