@import "../../../stylesheets/variables";
@import "../../../stories/stylesheets/functions";

.NavModal {
  width: 90vw;
  min-width: 600px;
  max-width: 1100px;
  height: $app-nav-modal-height;
  min-height: $app-nav-modal-min-height;
  max-height: $app-nav-modal-max-height;
  display: flex;
  background-color: var(--#{$prefix}body-bg) !important;

  .LeftNavBar {
    flex-shrink: 0;
    width: 260px;
    height: 100%;
    overflow-y: auto;
    background-color: var(--#{$prefix}secondary-bg);
    border-right: 1px solid var(--#{$prefix}border-secondary-color);

    .navSectionLabel {
      font-size: var(--font-size-sm);
      color: var(--color-text-lightest);
      padding: 10px 15px;
    }

    .navItem {
      height: 40px;
      padding: 0 6px;
      cursor: pointer;
      user-select: none;
      display: flex;
      align-items: center;
      margin-bottom: 1px;

      &:hover {
        background-color: var(--#{$prefix}tertiary-bg);
      }

      &.actived {
        background-color: var(--#{$prefix}body-bg);
      }

      &:active {
        // @include theme(background-color, navBarItemActive);
      }

      .icon {
        width: 40px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }

  .Main {
    flex: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    // @include theme(background-color, pageNorm);

    .Body {
      height: subtract($app-nav-modal-height, $app-nav-modal-header-height);
      min-height: subtract($app-nav-modal-min-height, $app-nav-modal-header-height);
      max-height: subtract($app-nav-modal-max-height, $app-nav-modal-header-height);
    }
  }
}