@import "../../../stylesheets/variables";
.BoardView {
  display: flex;
  padding: 0px 20px 10px 20px;
  overflow-x: auto;

  &::-webkit-scrollbar {
    width: 20px;
    height: 20px;
  }

  .TaskColumnDraggingCls {
    box-shadow: 0px 0px 10px 0px rgba(9, 30, 66, 0.2);
  }

  .TaskColumn {
    width: 350px;
    flex-shrink: 0;
    margin-right: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    overflow: hidden;
    background-color: var(--#{$prefix}secondary-bg);
    border: 1px solid var(--#{$prefix}border-secondary-color);


    .Header {
      padding: 15px 20px 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    .TaskList {
      height: calc(100vh - 280px);
      overflow-y: auto;
      padding: 10px 0 10px 15px;

      &::-webkit-scrollbar {
        width: 4px;
        height: 4px;
      }

      .TaskItemDragging {
        transform: rotate(3deg);
        //box-shadow: 2px 5px 5px 0px rgba(9, 30, 66, 0.1);
      }

      .TaskItem {
        transition: background 0.1s ease 0s;
        box-shadow: var(--#{$prefix}box-shadow-xs);
        padding: 10px;
        border-radius: 5px;
        cursor: initial !important;
        user-select: none;
        //margin-bottom: 5px;
        min-height: 60px;
        width: 300px;
        display: flex;
        margin: 5px 0 5px 5px;
        background-color: var(--#{$prefix}body-bg);

        &:hover {
          //background-color: rgb(235, 236, 240);
        }

        &.isDone {
          .title {
            text-decoration: line-through;
          }
        }

        .AsDoneIcon {
          border: 2px solid #eee;
          border-radius: 100px;
        }

        .content {
          padding-left: 10px;

          .title {
            line-height: 1.2;
          }

          .createdDate {
            color: #666;
          }

          .participantsRow {
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: space-between;
          }
        }

      }

    }

  }


}