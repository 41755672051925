.#{$prefix}avatar {
  --#{$prefix}avatar-width-height: #{$avatar-width-height};
  --#{$prefix}avatar-border-radius: #{$avatar-border-radius};

  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  color: white;
  @apply font-semibold overflow-hidden;

  &.size-2xs {
    @apply w-5 h-5 rounded text-xs;
  }

  &.size-xs {
    @apply w-6 h-6 rounded text-sm;
  }

  &.size-sm {
    @apply w-8 h-8 rounded text-lg;
  }

  &.size-md {
    @apply w-10 h-10 rounded text-2xl;
  }

  &.size-lg {
    @apply w-12 h-12 rounded-lg text-3xl;
  }

  &.size-xl {
    @apply w-16 h-16 rounded-lg text-4xl;
  }

  &.size-2xl {
    @apply w-20 h-20 rounded-xl text-6xl;
  }
}

.#{$prefix}avatar-group {
  display: flex;
  align-items: center;
  position: relative;

  .item {
    position: relative;
    border-radius: 100px;
    overflow: hidden;

    &:hover {
      //z-index: 1;
    }
  }

  .maxInfo {
    position: relative;
    font-size: 13px;
    color: #333;

  }
}