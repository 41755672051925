@import "../../../stories/stylesheets/functions";
@import "../../../stories/stylesheets/variables";

.ChooseAvatarGird {
  max-height: 160px;
  margin-bottom: 20px;
  padding: 5px 0;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;

  grid-gap: 10px;
  box-sizing: border-box;

  .Option {
    display: flex;
    padding: 2px;
    border-radius: 10px;
    border: 3px solid transparent;
    width: max-content;
    cursor: pointer;

    &:hover {
      border-color: rgba(0, 0, 0, 0.1);
    }

    &.Actived {
      border-color: var(--mexui-primary);
    }
  }
}

.userAvatarCropPreview {
  width: 100px;
  height: 100px;
  border-radius: 10px;
  overflow: hidden;
}

.TooBigErrorBox {
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  .emoji {
    font-size: 22px;
  }

  .tips {
    padding: 5px;
    max-width: 300px;
  }
}

.ItemLabel, .SubLabel {
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.SubLabel {
  font-size: var(--mexui-body-font-size-sm);
  max-width: 500px;
  color: var(--mexui-secondary-color);
}

.ItemRow {
  padding: 10px 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;


  .defaultValue {
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: var(--mexui-body-font-size);
  }

  .prompt {
    font-size: var(--mexui-body-font-size-xs);
    color: var(--mexui-secondary-color);
  }
}

.ThemeColorTag {
  width: 16px;
  height: 16px;
  border-radius: 100px;

  &.default {
    @apply bg-stone-200;
  }

  &.red {
    background-color: #{$rose-600};
  }

  &.blue {
    background-color: #{$blue-600};
  }

  &.orange {
    background-color: #{$orange-600};
  }

  &.pink {
    background-color: #{$pink-600};
  }

  &.indigo {
    background-color: #{$indigo-600};
  }

  &.green {
    background-color: #{$green-600};
  }

  &.cyan {
    background-color: #{$cyan-600};
  }

  &.teal {
    background-color: #{$teal-600};
  }
}