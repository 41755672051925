.#{$prefix}alert {
  // scss-docs-start alert-css-vars
  --#{$prefix}alert-bg: transparent;
  --#{$prefix}alert-padding-x: #{$alert-padding-x};
  --#{$prefix}alert-padding-y: #{$alert-padding-y};
  --#{$prefix}alert-margin-bottom: #{$alert-margin-bottom};
  --#{$prefix}alert-color: inherit;
  --#{$prefix}alert-border-color: transparent;
  --#{$prefix}alert-border: #{$alert-border-width} solid var(--#{$prefix}alert-border-color);
  --#{$prefix}alert-border-radius: #{$alert-border-radius};
  --#{$prefix}alert-link-color: inherit;
  --#{$prefix}alert-font-size: inherit;
  // scss-docs-end alert-css-vars

  display: flex;
  align-items: center;
  padding: var(--#{$prefix}alert-padding-y) var(--#{$prefix}alert-padding-x);
  margin-bottom: var(--#{$prefix}alert-margin-bottom);
  color: var(--#{$prefix}alert-color);
  background-color: var(--#{$prefix}alert-bg);
  border: var(--#{$prefix}alert-border);
  font-size: var(--#{$prefix}alert-font-size);
  font-weight: 600;

  @include border-radius(var(--#{$prefix}alert-border-radius));

  //
  // Alert Sizes
  //

  &.size-lg {
    @include alert-size($alert-padding-y, $alert-padding-x, $btn-font-size-lg,$alert-border-radius);
  }

  &.size-sm {
    @include alert-size($alert-padding-y, $alert-padding-x, $btn-font-size-sm, $alert-border-radius);
  }
}

// scss-docs-start alert-modifiers
// Generate contextual modifier classes for colorizing the alert
@each $color, $value in $theme-colors {
  //  Alert variant style in light mode --- Start
  @include color-mode(light) {
    .#{$prefix}alert {
      &.outline-#{$color} {
        @include alert-variant(
                $background: tint-color($value, 85%),
                $border: tint-color($value, 60%),
                $color: $body-color
        );
      }

      &.solid-#{$color} {
        @include alert-variant(
                $background: tint-color($value, 85%),
                $border: tint-color($value, 85%),
                $color: $body-color
        );
      }
    }
  }
  //  Alert variant style in light mode --- End

  //  Alert variant style in dark mode --- Start
  @include color-mode(dark) {
    .#{$prefix}alert {
      &.outline-#{$color} {
        @include alert-variant(
                $background: shade-color($value, 60%),
                $border: shade-color($value, 30%),
                $color: $body-color-dark
        );
      }

      &.solid-#{$color} {
        @include alert-variant(
                $background: shade-color($value, 60%),
                $border: shade-color($value, 60%),
                $color: $body-color-dark
        );
      }
    }
  }
  //  Alert variant style in dark mode --- End

}

// scss-docs-end alert-modifiers

.#{$prefix}alertIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: $alert-padding-y;

  @each $color, $value in $theme-colors {
    &.solid-#{$color} {
      color: $value;
    }
    &.outline-#{$color} {
      color: $value;
    }
  }

}