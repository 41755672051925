@import "../../stories/stylesheets/functions";
@import "../../stories/stylesheets/variables";
@import "../../stories/stylesheets/variables-dark";
@import "../../stories/stylesheets/mixins/color-mode";
@import "../../stories/stylesheets/mixins/text-truncate";
@import "../../stylesheets/mixins/breakpoints";

//  media-breakpoint start

//  media-breakpoint end
.appNavBar {
  --app-nav-bar-bg: var(--#{$prefix}secondary-bg);
  --app-nav-bar-color: var(--#{$prefix}body-color);
  --app-nav-bar-item-hover-bg: var(--#{$prefix}secondary-hover-bg);
  --app-nav-bar-item-active-bg: var(--#{$prefix}secondary-active-bg);
  --app-nav-bar-border-color: var(--#{$prefix}border-secondary-color);
  --app-left-nav-bar-padding-x: #{$spacer};
  --app-left-nav-bar-padding-y: #{$spacer-sm};

  background-color: var(--app-nav-bar-bg);
  color: var(--app-nav-bar-color);
}

//  LeftNavBar styles start
.LeftNavBar {
  --app-left-nav-bar-width: #{$app-left-nav-bar-width-lg};

  width: var(--app-left-nav-bar-width);
  height: 100vh;
  flex-shrink: 0;
  font-weight: 600;
  user-select: none;
  border-right: 1px solid var(--app-nav-bar-border-color);


  //@include media-breakpoint-showMediumApp() {
  //  --app-left-nav-bar-width: #{$app-left-nav-bar-width};
  //}

  @include media-breakpoint-showSmallApp() {
    display: none;
  }

  .ProfileSection {
    @apply p-2 mx-4 mt-4 mb-3 rounded;

    //@include media-breakpoint-showMediumApp() {
    //  @apply mx-1;
    //}
  }
}

//  LeftNavBar styles end

//  TopNavBar styles start
.TopNavBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 #{$spacer-sm};
  width: 100%;
  height: #{$app-top-nav-bar-height};

  @include media-breakpoint-up(lg) {
    display: none;
  }

  .ProfileSection {
    direction: rtl;
    height: #{subtract($app-top-nav-bar-height,10px)};
    padding: 0 #{$spacer-sm};
  }

  .ToggleBtn {
    --mexui-btn-hover-bg: var(--app-nav-bar-item-hover-bg) !important;
    --mexui-btn-hover-border-color: var(--app-nav-bar-item-hover-bg) !important;
    --mexui-btn-active-bg: var(--app-nav-bar-item-active-bg) !important;
    --mexui-btn-active-border-color: var(--app-nav-bar-item-active-bg) !important;
    color: var(--app-nav-bar-color);
  }
}

//  TopNavBar styles end

.NavList {
  height: calc(100vh - 100px);
  overflow-y: auto;
  @apply px-4;

  //@include media-breakpoint-showMediumApp() {
  //  @apply px-1;
  //}

  .NavItem {
    @apply flex items-center select-none cursor-pointer h-9 pl-2 my-[1px] rounded transition-colors;

    &:hover {
      background-color: var(--app-nav-bar-item-hover-bg);
    }

    &:active {
      background-color: var(--app-nav-bar-item-active-bg);
    }

    &.active {
      background-color: var(--app-nav-bar-item-active-bg);
      @apply opacity-100;
    }

    .icon {
      margin: 0 5px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .label {
      @apply flex-1 font-medium truncate;
    }

    .badge {
      background-color: red;
      color: white;
      padding: 2px;
      border-radius: 5px;
    }

    //@include media-breakpoint-showMediumApp() {
    //  padding: 14px 0;
    //  .icon {
    //    margin: 0;
    //    transform: scale(1.1);
    //  }
    //}
  }

  .SectionTitle {
    padding: 10px;
    font-size: var(--mexui-body-font-size-sm);
    opacity: 0.6;
  }

  .WorkspaceList {

    .WorkspaceItem {
      padding: 5px 20px 5px 30px;
      border-radius: 5px;
      cursor: pointer;
      margin-bottom: 1px;
      display: block;

      &:hover {
        background-color: var(--app-nav-bar-item-hover-bg);
      }

      &.active {
        background-color: var(--app-nav-bar-item-active-bg);
        opacity: 1;
      }
    }
  }
}

.ProfileSection {
  display: flex;
  align-items: center;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background-color: var(--app-nav-bar-item-hover-bg);
  }

  &:active {
    background-color: var(--app-nav-bar-item-active-bg);
  }

  .content {
    flex: 1;
    padding: 0 10px;
    overflow: hidden;
  }

  .title {
    font-size: var(--mexui-body-font-size);
    font-weight: 600;
    margin-bottom: 2px;
  }

  .subTitle {
    flex: 1;
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-size: var(--mexui-body-font-size-sm);
  }
}

.NavBarDrawer {
  top: $app-top-nav-bar-height !important;

  .NavBarDrawerContent {
    width: $app-left-nav-bar-width-lg;
    border-radius: 0;
    box-shadow: var(--mexui-box-shadow);
    border-right: 1px solid var(--mexui-border-color);

    .NavList {
      padding-top: 20px;
      height: #{subtract(100vh,$app-top-nav-bar-height)}
    }
  }
}


//  colorSchemes --- Start
@include color-mode(light) {
  .appNavBar {
    &[data-color=blue] {
      --app-nav-bar-bg: #{$blue-600};
      --app-nav-bar-border-color: #{$blue-600};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$blue-700};
      --app-nav-bar-item-active-bg: #{$blue-700};
    }

    &[data-color=orange] {
      --app-nav-bar-bg: #{$orange-600};
      --app-nav-bar-border-color: #{$orange-600};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$orange-700};
      --app-nav-bar-item-active-bg: #{$orange-700};
    }

    &[data-color=red] {
      --app-nav-bar-bg: #{$red-600};
      --app-nav-bar-border-color: #{$red-600};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$red-700};
      --app-nav-bar-item-active-bg: #{$red-700};
    }

    &[data-color=pink] {
      --app-nav-bar-bg: #{$pink-600};
      --app-nav-bar-border-color: #{$pink-600};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$pink-700};
      --app-nav-bar-item-active-bg: #{$pink-700};
    }

    &[data-color=indigo] {
      --app-nav-bar-bg: #{$indigo-600};
      --app-nav-bar-border-color: #{$indigo-600};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$indigo-700};
      --app-nav-bar-item-active-bg: #{$indigo-700};
    }

    &[data-color=green] {
      --app-nav-bar-bg: #{$green-600};
      --app-nav-bar-border-color: #{$green-600};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$green-700};
      --app-nav-bar-item-active-bg: #{$green-700};
    }

    &[data-color=cyan] {
      --app-nav-bar-bg: #{$cyan-600};
      --app-nav-bar-border-color: #{$cyan-600};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$cyan-700};
      --app-nav-bar-item-active-bg: #{$cyan-700};
    }

    &[data-color=teal] {
      --app-nav-bar-bg: #{$teal-600};
      --app-nav-bar-border-color: #{$teal-600};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$teal-700};
      --app-nav-bar-item-active-bg: #{$teal-700};
    }
  }
}

@include color-mode(dark) {
  .appNavBar {
    &[data-color=blue] {
      --app-nav-bar-bg: #{$blue-700};
      --app-nav-bar-border-color: #{$blue-700};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$blue-800};
      --app-nav-bar-item-active-bg: #{$blue-800};
    }

    &[data-color=orange] {
      --app-nav-bar-bg: #{$orange-700};
      --app-nav-bar-border-color: #{$orange-700};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$orange-800};
      --app-nav-bar-item-active-bg: #{$orange-800};
    }

    &[data-color=red] {
      --app-nav-bar-bg: #{$red-700};
      --app-nav-bar-border-color: #{$red-700};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$red-800};
      --app-nav-bar-item-active-bg: #{$red-800};
    }

    &[data-color=pink] {
      --app-nav-bar-bg: #{$pink-700};
      --app-nav-bar-border-color: #{$pink-700};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$pink-800};
      --app-nav-bar-item-active-bg: #{$pink-800};
    }

    &[data-color=indigo] {
      --app-nav-bar-bg: #{$indigo-700};
      --app-nav-bar-border-color: #{$indigo-700};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$indigo-800};
      --app-nav-bar-item-active-bg: #{$indigo-800};
    }

    &[data-color=green] {
      --app-nav-bar-bg: #{$green-700};
      --app-nav-bar-border-color: #{$green-700};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$green-800};
      --app-nav-bar-item-active-bg: #{$green-800};
    }

    &[data-color=cyan] {
      --app-nav-bar-bg: #{$cyan-700};
      --app-nav-bar-border-color: #{$cyan-700};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$cyan-800};
      --app-nav-bar-item-active-bg: #{$cyan-800};
    }

    &[data-color=teal] {
      --app-nav-bar-bg: #{$teal-700};
      --app-nav-bar-border-color: #{$teal-700};
      --app-nav-bar-color: white;
      --app-nav-bar-item-hover-bg: #{$teal-800};
      --app-nav-bar-item-active-bg: #{$teal-800};
    }
  }
}

//  colorSchemes --- End
