.mentions--multiLine {
  div:last-of-type {
    background-color: transparent !important;
  }

  .mentions__input, .mentions__highlighter {
    padding: 0px 18px;
    border: none !important;
    resize: none;
    outline: none;
    font-weight: 600;
    overflow: auto !important;
    max-height: 200px !important;
    font-size: var(--font-size-lg);
    line-height: 1.6;

    &::placeholder {
      color: #ccc;
      user-select: none;
    }
  }

  .mentions__highlighter {
    overflow: hidden !important;
    user-select: none;
  }

}

.mentions__suggestions__list {
  background-color: white;
  box-shadow: 0 8px 24px rgba(140, 149, 159, 0.2);
  border: 1px solid #ddd;
  overflow: hidden;
  border-radius: 5px;
  width: 200px;
  max-height: 300px;
  overflow-y: auto;
}

.mentions__suggestions__item--focused {
  background-color: #eee;
}

.MentionSuggestionItem {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border-bottom: 1px solid #f5f5f5;

  .name {
    padding: 0 6px;
    font-size: var(--font-size-sm);
  }
}