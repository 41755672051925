.#{$prefix}btn {
  // scss-docs-start btn-css-vars
  --#{$prefix}btn-padding-x: #{$btn-padding-x};
  --#{$prefix}btn-padding-y: #{$btn-padding-y};
  --#{$prefix}btn-height: #{$btn-height};
  --#{$prefix}btn-font-family: #{$btn-font-family};
  @include rfs($btn-font-size, --#{$prefix}btn-font-size);
  --#{$prefix}btn-font-weight: #{$btn-font-weight};
  //--#{$prefix}btn-line-height: #{$btn-line-height};
  --#{$prefix}btn-color: #{$btn-color};
  --#{$prefix}btn-bg: transparent;
  --#{$prefix}btn-border-width: #{$btn-border-width};
  --#{$prefix}btn-border-color: transparent;
  --#{$prefix}btn-border-radius: #{$btn-border-radius};
  --#{$prefix}btn-hover-border-color: transparent;
  --#{$prefix}btn-box-shadow: #{$btn-box-shadow};
  --#{$prefix}btn-disabled-opacity: #{$btn-disabled-opacity};
  --#{$prefix}btn-focus-box-shadow: 0 0 0 #{$btn-focus-width} rgba(var(--#{$prefix}btn-focus-shadow-rgb), .5);
  // scss-docs-end btn-css-vars

  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  color: var(--#{$prefix}btn-color);
  text-align: center;
  text-decoration: if($link-decoration == none, null, none);
  white-space: $btn-white-space;
  vertical-align: middle;
  cursor: if($enable-button-pointers, pointer, null);
  user-select: none;
  border: var(--#{$prefix}btn-border-width) solid var(--#{$prefix}btn-border-color);
  @include border-radius(var(--#{$prefix}btn-border-radius));
  @include gradient-bg(var(--#{$prefix}btn-bg));
  @include transition($btn-transition);
  @apply rounded h-9 px-4 font-semibold transition-colors;

  &:hover {
    color: var(--#{$prefix}btn-hover-color);
    text-decoration: if($link-hover-decoration == underline, none, null);
    background-color: var(--#{$prefix}btn-hover-bg);
    border-color: var(--#{$prefix}btn-hover-border-color);
  }

  &:focus-visible {
    color: var(--#{$prefix}btn-hover-color);
    @include gradient-bg(var(--#{$prefix}btn-hover-bg));
    border-color: var(--#{$prefix}btn-hover-border-color);
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: var(--#{$prefix}btn-box-shadow), var(--#{$prefix}btn-focus-box-shadow);
    } @else {
      box-shadow: var(--#{$prefix}btn-focus-box-shadow);
    }
  }

  &:active {
    color: var(--#{$prefix}btn-active-color);
    background-color: var(--#{$prefix}btn-active-bg);
    // Remove CSS gradients if they're enabled
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-active-border-color);
    @include box-shadow(var(--#{$prefix}btn-active-shadow));

    &:focus-visible {
      // Avoid using mixin so we can pass custom focus shadow properly
      @if $enable-shadows {
        box-shadow: var(--#{$prefix}btn-active-shadow), var(--#{$prefix}btn-focus-box-shadow);
      } @else {
        box-shadow: var(--#{$prefix}btn-focus-box-shadow);
      }
    }
  }


  &:disabled,
  &.disabled,
  fieldset:disabled & {
    color: var(--#{$prefix}btn-disabled-color);
    pointer-events: none;
    background-color: var(--#{$prefix}btn-disabled-bg);
    background-image: if($enable-gradients, none, null);
    border-color: var(--#{$prefix}btn-disabled-border-color);
    opacity: var(--#{$prefix}btn-disabled-opacity);
    @include box-shadow(none);
  }

  // scss-docs-end btn-variant-loops

  //
  // Button Sizes
  //

  &.size-lg {
    @apply rounded h-10 px-6;
  }

  &.size-sm {
    //@include button-size($btn-padding-y-sm, $btn-padding-x-sm, $btn-font-size-sm, $btn-border-radius-sm, $font-weight-semibold, $btn-height-sm);
    @apply text-sm rounded h-7 px-3;
  }

  &.isBlock {
    width: 100%;
  }


  &.square {
    @apply h-9 w-9 p-0;
    &.size-sm {
      @apply h-7 w-7;
    }

    &.size-lg {
      @apply h-10 w-10;
    }
  }

  &.circle{
    @apply rounded-full;
  }
}


//
// Alternate buttons
//

// scss-docs-start btn-variant-loops
@each $color, $value in $theme-colors {
  //  Button variant style in light mode --- Start
  @include color-mode(light) {
    .#{$prefix}btn {
      &.solid-#{$color} {
        @if $color == "light" {
          @include button-variant(
                          $value,
                  $hover-background: shade-color($value, 3%),
                  $active-background: shade-color($value, 12%),
                  $active-color: $body-color
          );
        } @else if $color == "secondary" {
          @include button-variant(
                          $btn-secondary-bg,
                  $hover-background: shade-color($body-secondary-bg, $btn-secondary-hover-bg-shade-amount),
                  $active-background: shade-color($body-secondary-bg, $btn-secondary-active-bg-shade-amount)
          );
        } @else {
          @include button-variant($value);
        }
      }

      &.outline-#{$color} {
        @if $color == "light" {
          @include button-outline-variant(
                          $body-color,
                  $background: $value,
                  $border-color: shade-color($body-secondary-bg, 8%),
                  $hover-background: shade-color($value, 2%),
                  $active-background: shade-color($value, 8%)
          );
        } @else if $color == "secondary" {
          @include button-outline-variant(
                          color-contrast($btn-secondary-bg),
                  $border-color: shade-color($btn-secondary-bg, 10%),
                  $hover-background: $btn-secondary-bg,
                  $active-background: shade-color($btn-secondary-bg, $btn-secondary-hover-bg-shade-amount)
          );
        } @else {
          @include button-outline-variant(
                          $value,
                  $border-color: $value,
                  $color-hover: $value,
                  $hover-background: tint-color($value, 90%),
                  $active-background: tint-color($value, 80%)
          );
        }
      }

      &.ghost-#{$color} {
        @if $color == "light" {
          @include button-ghost-variant(
                          $body-color,
                  $hover-background: shade-color($value, 6%),
                  $active-background: shade-color($value, 12%)
          );
        } @else if $color == "secondary" {
          @include button-ghost-variant(
                          color-contrast($btn-secondary-bg),
                  $hover-background: $btn-secondary-bg,
                  $active-background: shade-color($btn-secondary-bg, $btn-secondary-hover-bg-shade-amount)
          );
        } @else {
          @include button-ghost-variant(
                          $value,
                  $hover-background: tint-color($value, 90%),
                  $active-background: tint-color($value, 70%)
          );
        }
      }
    }
  }
  //  Button variant style in light mode --- End


  //  Button variant style in dark mode --- Start
  @include color-mode(dark) {
    .#{$prefix}btn {
      &.solid-#{$color} {
        @if $color == "light" {
          @include button-variant(
                          tint-color($body-secondary-bg-dark, 20%),
                  $hover-background: shade-color($gray-600, 9%),
                  $active-background: shade-color($gray-600, $btn-active-bg-shade-amount)
          );
        } @else if $color == "secondary" {
          @include button-variant(
                          $secondary-dark,
                  $hover-background: tint-color($secondary-dark, 8%),
                  $active-background: $secondary-dark
          );
        } @else {
          @include button-variant($value);
        }
      }

      &.outline-#{$color} {
        @if $color == "light" {
          @include button-outline-variant(
                          $body-color-dark,
                  $background: $secondary-dark,
                  $border-color: $secondary-dark,
                  $hover-background: tint-color($secondary-dark, 4%),
                  $active-background: shade-color($secondary-dark, 9%)
          );
        } @else if $color == "secondary" {
          @include button-outline-variant(
                          $body-color-dark,
                  $border-color: $gray-500,
                  $hover-background: shade-color($gray-400, 80%),
                  $active-background: shade-color($gray-400, 90%)
          );
        } @else {
          @include button-outline-variant(
                          $value,
                  $border-color: $value,
                  $hover-background: shade-color($value, 70%),
                  $active-background: shade-color($value, 80%)
          );
        }
      }

      &.ghost-#{$color} {
        @if $color == "secondary" {
          @include button-ghost-variant(
                          $body-color-dark,
                  $hover-background: tint-color($secondary-dark, 4%),
                  $active-background: shade-color($secondary-dark, 9%)
          );
        } @else {
          @include button-ghost-variant(
                          $value,
                  $hover-background: shade-color($value, 70%),
                  $active-background: shade-color($value, 80%)
          );
        }
      }
    }
    //  Button variant style in dark mode --- End

  }
}



