.#{$prefix}link {

  outline: 0;
  cursor: pointer;
  width: max-content;

  &:hover {
    text-decoration: underline;
  }

  &.size-auto {
    font-size: inherit;
  }
}