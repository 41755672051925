.DocumentSpacePathBar {
  display: flex;
  align-items: center;
  padding: 0 5px;

  &.sm {
    font-size: var(--font-size-sm);
  }

  &.md {
    font-size: var(--font-size-md);
  }

  &.lg {
    font-size: var(--font-size-lg);
  }

  .PathItem {
    display: flex;
    align-items: center;

    .label {
      padding: 5px 0px;
      cursor: pointer;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      &:hover {
        text-decoration: underline;
      }
    }

    .separator {
      height: 20px;
      padding: 0 4px;
      color: #666;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        width: 12px;
        height: 12px;
      }
    }

  }

}