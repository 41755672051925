@import "../../../stylesheets/variables";
@import "../../../stories/stylesheets/functions";

.ItemLabel, .SubLabel {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.SubLabel {
  font-size: var(--#{$prefix}body-font-size-sm);
  max-width: 500px;
}

.ItemRow {
  padding: 12px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;


  .defaultValue {
    font-weight: 600;
    display: flex;
    align-items: center;
    font-size: var(--#{$prefix}body-font-size-lg);
  }

  .prompt {
    font-size: var(--#{$prefix}body-font-size-xs);
    color: var(--#{$prefix}secondary-color);
  }
}

.Panel {
  $space-y: 110px;
  padding: 0px 40px;
  height: subtract($app-nav-modal-height, $space-y);
  min-height: subtract($app-nav-modal-min-height, $space-y);
  max-height: subtract($app-nav-modal-max-height, $space-y);
}

.InviteLinkBox {
  display: flex;
  align-items: center;
  border: 1px solid;
  padding: 3px;
  border-radius: 5px;
  // @include theme(background-color, borderLightest);
  // @include theme(border-color, borderLight);

  .link {
    flex: 1;
    padding: 0 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

