// @import "../../../stories/stylesheets/mexui";

.SearchModal {
  width: 90vw;
  height: 90vh;
  min-width: 600px;
  max-width: 600px;
  max-height: 700px;
  min-height: 400px;
  border-radius: 10px;
  // @include theme(background-color, pageNorm);

}