@import "../../../stylesheets/variables";
@import "../../../stories/stylesheets/functions";

.Modal {
  width: 90vw;
  min-width: 600px;
  max-width: 600px;
  height: 400px;
  border-radius: 10px;

}