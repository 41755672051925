.ChatMessageItem {
  display: flex;
  padding: 10px 0px;
  min-width: 300px;

  &:after {
    content: ' ';
    display: block;
    width: 50px;
    flex-shrink: 0;
  }

  &.rtl {
    flex-direction: row-reverse;
  }

  .avatar {
    cursor: pointer;
  }

  .name {
    padding-bottom: 3px;
    font-weight: 500;
    font-size: var(--mexui-body-font-size-sm);
  }

  .content {
    padding: 10px 12px;
    border-radius: 6px;
    max-width: 600px;
    background-color: var(--mexui-body-bg);
  }

  .attachment {

  }
}