.ResizableListHeader {
  user-select: none;
  height: 36px;
  width: max-content;
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid var(--mexui-border-color);
  background-color: var(--mexui-body-bg);
  font-size: var(--mexui-body-font-size-sm);
  border-bottom: 1px solid var(--mexui-border-tertiary-color);

  .col {
    padding: 0px 8px;

    &.nameCol {

    }
  }

  .resizable {
    display: flex;
    align-items: center;
    height: 100% !important;
    justify-content: flex-start !important;
    padding-left: 12px;


    .rightResizerHandler {
      display: flex;
      justify-content: center;
      width: 14px !important;
      cursor: w-resize !important;
      right: 0 !important;

      &:hover {
        &:before {
          background-color: var(--mexui-tertiary-color);
        }
      }

      &:active {
        &:before {
          background-color: var(--mexui-tertiary-color);
          box-shadow: var(--mexui-box-shadow-sm);
        }
      }

      &:before {
        content: ' ';
        display: block;
        width: 1px !important;
        height: 100%;
        transition: all 200ms;
        background-color: var(--mexui-border-secondary-color);
      }
    }

  }

}