//
// General form controls (plus a few specific high-level interventions)
//

.#{$prefix}form-textarea {
  @apply w-full border border-stone-300 hover:border-stone-400 font-medium px-3 py-2 rounded-md focus:outline-primary-600 focus:outline-2;
}

.#{$prefix}form-input {
  --#{$prefix}btn-height: #{$btn-height};

  --#{$prefix}form-input-padding-x: #{$input-padding-x};

  --#{$prefix}form-input-border-color: #{$input-border-color};
  --#{$prefix}form-input-hover-border-color: #{$input-hover-border-color};

  --#{$prefix}form-input-color: #{$input-color};
  --#{$prefix}form-input-bg: #{$input-bg};
  --#{$prefix}form-input-hover-bg: #{$input-hover-bg};
  --#{$prefix}form-input-placeholder-color: #{$input-placeholder-color};

  --#{$prefix}form-input-disable-color: #{$input-disabled-color};
  --#{$prefix}form-input-disable-bg: var(--#{$prefix}secondary-bg);
  --#{$prefix}form-input-disable-placeholder-color: #{$input-disabled-placeholder-color};

  --#{$prefix}form-input-box-shadow: none;

  --#{$prefix}form-input-focus-bg: none;
  --#{$prefix}form-input-focus-border-color: #{$primary};
  --#{$prefix}form-input-focus-box-shadow: #{$input-focus-box-shadow};


  display: flex;
  align-items: center;
  overflow: hidden;
  width: 100%;
  height: $input-btn-height;
  color: var(--#{$prefix}form-input-color);
  background-color: var(--#{$prefix}form-input-bg);
  background-clip: padding-box;
  @apply border border-stone-300 hover:border-stone-400/80 rounded-md transition-colors;
  appearance: none; // Fix appearance for date inputs in Safari
  outline: 0;
  box-shadow: var(--#{$prefix}form-input-box-shadow);
  font-weight: 600;

  input {
    width: 100%;
    height: 100%;
    outline: none;
    background: inherit;
    font-size: inherit;
    border: 0;
    flex: 1;
    padding: 0 var(--#{$prefix}form-input-padding-x);

    &[type="file"] {
      overflow: hidden; // prevent pseudo element button overlap

      &:not(:disabled):not([readonly]) {
        cursor: pointer;
      }
    }

    // Placeholder
    &::placeholder {
      color: var(--#{$prefix}form-input-placeholder-color);
      // Override Firefox's unusual default opacity; see https://github.com/twbs/bootstrap/pull/11526.
      opacity: 1;
    }

    // Disabled inputs
    //
    // HTML5 says that controls under a fieldset > legend:first-child won't be
    // disabled if the fieldset is disabled. Due to implementation difficulty, we
    // don't honor that edge case; we style them as disabled anyway.
    &:disabled {
      // iOS fix for unreadable disabled content; see https://github.com/twbs/bootstrap/issues/11655.
      opacity: .8;
    }

    &::-webkit-date-and-time-value {
      // On Android Chrome, form-control's "width: 100%" makes the input width too small
      // Tested under Android 11 / Chrome 89, Android 12 / Chrome 100, Android 13 / Chrome 109
      //
      // On iOS Safari, form-control's "appearance: none" + "width: 100%" makes the input width too small
      // Tested under iOS 16.2 / Safari 16.2
      min-width: 85px; // Seems to be a good minimum safe width

      // Add some height to date inputs on iOS
      // https://github.com/twbs/bootstrap/issues/23307
      // TODO: we can remove this workaround once https://bugs.webkit.org/show_bug.cgi?id=198959 is resolved
      // Multiply line-height by 1em if it has no unit
      height: if(unit($input-line-height) == "", $input-line-height * 1em, $input-line-height);

      // Android Chrome type="date" is taller than the other inputs
      // because of "margin: 1px 24px 1px 4px" inside the shadow DOM
      // Tested under Android 11 / Chrome 89, Android 12 / Chrome 100, Android 13 / Chrome 109
      margin: 0;
    }

    // Prevent excessive date input height in Webkit
    // https://github.com/twbs/bootstrap/issues/34433
    &::-webkit-datetime-edit {
      display: block;
      padding: 0;
    }

    // File input buttons theming
    &::file-selector-button {
      padding: $input-padding-y $input-padding-x;
      margin: (-$input-padding-y) (-$input-padding-x);
      margin-inline-end: $input-padding-x;
      color: $form-file-button-color;
      @include gradient-bg($form-file-button-bg);
      pointer-events: none;
      border-color: inherit;
      border-style: solid;
      border-width: 0;
      border-inline-end-width: $input-border-width;
      border-radius: 0; // stylelint-disable-line property-disallowed-list
      @include transition($btn-transition);
    }

    &:hover:not(:disabled):not([readonly])::file-selector-button {
      background-color: $form-file-button-hover-bg;
    }
  }

  // Note: This has no effect on <select>s in some browsers, due to the limited stylability of `<select>`s in CSS.
  @include box-shadow($input-box-shadow);
  @include transition($input-transition);

  &:hover {
    background-color: var(--#{$prefix}form-input-hover-bg);
  }

  &.variant-outline{
    &:focus-within {
      border-color: var(--#{$prefix}form-input-focus-border-color);
      background-color: var(--#{$prefix}form-input-focus-bg);
      box-shadow: var(--#{$prefix}form-input-focus-box-shadow);
    }
  }


  // scss-docs-start input-variant-loops
  // Customize the `:focus` state to imitate native WebKit styles.
  &.variant-filled {
    @apply bg-stone-100 border-stone-100;
  }

  .prefix, .suffix {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }

  // scss-docs-end input-variant-loops


  // Form control sizing
  //
  // Build on `.form-control` with modifier classes to decrease or increase the
  // height and font-size of form controls.
  //
  // Repeated in `_input_group.scss` to avoid Sass extend issues.

  &.size-sm {
    --#{$prefix}form-input-padding-x: #{$input-padding-x-sm};
    @apply text-sm rounded h-7;
  }

  &.size-lg {
    --#{$prefix}form-input-padding-x: #{$input-padding-x-lg};
    @apply text-lg rounded-lg h-10;
  }

}


@include color-mode(dark) {
  .#{$prefix}form-input {
    --#{$prefix}form-input-bg: #{$secondary-dark};
    --#{$prefix}form-input-hover-bg: #{$secondary-dark};
    --#{$prefix}form-input-border-color: #{$border-secondary-dark};
    --#{$prefix}form-input-hover-border-color: #{$secondary-dark};

    &.filled {
      --#{$prefix}form-input-bg: #{$secondary-dark};
      --#{$prefix}form-input-focus-bg: #{$secondary-dark};
      --#{$prefix}form-input-hover-bg: #{$secondary-dark};
      --#{$prefix}form-input-border-color: #{$secondary-dark};
      --#{$prefix}form-input-hover-border-color: #{$secondary-dark};
      --#{$prefix}form-input-focus-border-color: #{$secondary-dark};
    }
  }
}


// Readonly controls as plain text
//
// Apply class to a readonly input to make it appear like regular plain
// text (without any border, background color, focus indicator)

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: $input-padding-y 0;
  margin-bottom: 0; // match inputs if this class comes on inputs with default margins
  line-height: $input-line-height;
  color: $input-plaintext-color;
  background-color: transparent;
  border: solid transparent;
  border-width: $input-border-width 0;

  &:focus {
    outline: 0;
  }

  &.form-control-sm,
  &.form-control-lg {
    padding-right: 0;
    padding-left: 0;
  }
}


// Make sure textareas don't shrink too much when resized
// https://github.com/twbs/bootstrap/pull/29124
// stylelint-disable selector-no-qualifying-type
textarea {
  &.form-control {
    min-height: $input-height;
  }

  &.form-control-sm {
    min-height: $input-height-sm;
  }

  &.form-control-lg {
    min-height: $input-height-lg;
  }
}

// stylelint-enable selector-no-qualifying-type

.form-control-color {
  width: $form-color-width;
  height: $input-height;
  padding: $input-padding-y;

  &:not(:disabled):not([readonly]) {
    cursor: pointer;
  }

  &::-moz-color-swatch {
    border: 0 !important; // stylelint-disable-line declaration-no-important
    @include border-radius($input-border-radius);
  }

  &::-webkit-color-swatch {
    border: 0 !important; // stylelint-disable-line declaration-no-important
    @include border-radius($input-border-radius);
  }

  &.form-control-sm {
    height: $input-height-sm;
  }

  &.form-control-lg {
    height: $input-height-lg;
  }
}
