.#{$prefix}divider {
  --#{$prefix}divider-bg: #{$border-secondary};

  .line {
    height: 1px;
    background-color: var(--#{$prefix}divider-bg);
  }
}

@include color-mode(dark){
  .#{$prefix}divider {
    --#{$prefix}divider-bg: #{$border-secondary-dark};
}
}