$sizes: (
        sm:13px,
        md:16px,
        lg:22px,
        xl:30px,
);

.AsDoneIcon {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  border: 2px solid #ccc;
  font-size: 40px;
  cursor: pointer;

  &:hover {
    border-color: #aaa;

    svg {
      opacity: 1;
    }
  }

  &.isDone {
    border-color: #ccc;
    background-color: #ccc;

    svg {
      color: #fff;
      opacity: 1;
    }
  }

  @each $name, $size in $sizes {
    &.size-#{$name} {
      width: $size;
      height: $size;
      border-radius: $size;


    }
  }

  svg {
    color: #aaa;
    opacity: 0;
    transition: opacity 100ms;
  }
}