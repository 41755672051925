.DocumentSpaceActionRow {

}

.DocumentSpacePathRow {

}

.SearchBtn {
  cursor: text!important;
  input{
    cursor: text!important;
  }
}