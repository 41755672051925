.#{$prefix}tag {
  --#{$prefix}tag-padding-x: #{$tag-padding-x};
  --#{$prefix}tag-padding-y: #{$tag-padding-y};
  @include font-size($tag-font-size);

  --#{$prefix}tag-bg: #{$secondary};
  --#{$prefix}tag-color: #{$body-color};
  --#{$prefix}tag-border: #{$body-color};

  display: block;
  color: var(--#{$prefix}tag-color);
  border: 1px solid transparent;
  background-color: var(--#{$prefix}tag-bg);
  line-height: $tag-line-height;
  width: max-content;
  box-sizing: border-box;
  border-radius: 3px;
  user-select: none;
  padding: var(--#{$prefix}tag-padding-y) var(--#{$prefix}tag-padding-x);

  @each $color, $value in $tag-colors {
    &.solid-#{$color} {
      --#{$prefix}tag-bg: #{$value};
      --#{$prefix}tag-color: #{color-contrast($value)};
    }
    &.subtle-#{$color} {
      @if ($color=="gray") {
        --#{$prefix}tag-bg: #{tint-color($value,20%)};
        --#{$prefix}tag-color: #{shade-color($value,80%)};
      } @else {
        --#{$prefix}tag-bg: #{tint-color($value,80%)};
        --#{$prefix}tag-color: #{shade-color($value,40%)};
      }
    }
  }

  &.size-sm {
    --#{$prefix}tag-padding-x: #{$tag-padding-x-sm};
    --#{$prefix}tag-padding-y: #{$tag-padding-y-sm};
    @include font-size($tag-font-size-sm);
  }

  &.size-lg {
    --#{$prefix}tag-padding-x: #{$tag-padding-x-lg};
    --#{$prefix}tag-padding-y: #{$tag-padding-y-lg};
    @include font-size($tag-font-size-lg);
  }
}