@import "../../../stylesheets/variables";

.BaseModal {
  position: relative;
  outline: none;
  overflow: hidden;
  border-radius: 5px;
  background-color: var(--#{$prefix}dropdown-bg);
}

.BaseModalOverlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: $app-base-modal-zIndex;
  background-color: rgba(0, 0, 0, 0.7);
  outline: none;

  &.transparent {
    background-color: transparent !important;
  }

  &.contentCenter {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.ease-in-out {
    &.ReactModal__Overlay {
      background-color: rgba(0, 0, 0, 0);
      transition: background-color 500ms ease-in-out;
    }

    &.ReactModal__Overlay--after-open {
      background-color: rgba(0, 0, 0, 0.7);
    }

    &.ReactModal__Overlay--before-close {
      background-color: rgba(0, 0, 0, 0);
    }
  }
}


