.#{$prefix}row {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--#{$prefix}gutter-x);
}

.#{$prefix}flex {
  display: flex;
  flex-direction: column;
  flex: 1;
}