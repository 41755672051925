.CreateTaskBody {
  padding: 0 20px;

  .titleInput {
    font-weight: 600;
    line-height: 1.2;
    resize: none;
    outline: none;
    border: none;
    padding: 5px;
  }
}